import { GetCoinListTypeEnum } from '@/enums/businessEnum';
import usePageRefreshOnVisible from '@/hooks/usePageRefreshOnVisible';
import CoinOrderList from '@/pages/order/index/components/CoinOrderList';
import TransactionMode from '@/pages/transaction/components/TransactionMode';
import TransactionRightBox from '@/pages/transaction/components/TransactionRightBox';
import TransactionTopTitle from '@/pages/transaction/components/TransactionTopTitle';
import { Divider, Tab, Tabs } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useEffect, useState } from 'react';
import { CoinTradeContext, createCoinModel } from './coinModel';
import TransactionInformation from './components/TransactionInformation';
import TransactionLeftForm from './components/TransactionLeftForm';
import { TransactionTypeEnum } from './type';

export default () => {
  const modelProps = createCoinModel();

  const {
    onAmountChange,
    priceType,
    transactionMode,
    orderKey,
    orderTab,
    onOrderTabChange,
    orderTabsOption,
  } = modelProps;

  const { getTheListOfCoins ,localCoin} = useModel('coin');
  const { walletFundReq } = useModel('user');
  const [isBuyFull, setIsBuyFull] = useState(false);

  useEffect(() => {
    getTheListOfCoins({
      pageNo: 1,
      pageSize: 10,
      type: GetCoinListTypeEnum.COIN,
    });
  }, []);

  // 回到页面刷新
  const pageKey = usePageRefreshOnVisible();

  return (
    <CoinTradeContext.Provider value={modelProps}>
      <Tabs
        selectedKey={orderTab}
        onSelectionChange={onOrderTabChange as any}
        variant="underlined"
        // className="border-b-1  border-borderColor w-full"
        classNames={{
          tab: 'px-0 px-2 text-[18px]',
          tabList: 'pb-0 gap-0 pl-2',
          tabContent: 'group-data-[selected=true]:text-backContrastColor',
          cursor: 'hidden',
          panel: 'p-0',
        }}
      >
        {orderTabsOption.map((item: { value: string; text: string }) => {
          return (
            <Tab
              key={item.value}
              title={item.text}
              onChange={(value) => {
                console.log(value);
              }}
            ></Tab>
          );
        })}
      </Tabs>
      <Divider className="!bg-backgroundAuxiliaryColor/80" />

      <div className="w-full" key={pageKey}>
        {/*顶部大标题和图标*/}
        <TransactionTopTitle />
        {/*中间两列,左边是按钮组,右边是两个图表*/}
        <div className="flex px-4  mt-2 w-full box-border justify-between">
          <div className=" w-[60%] !flex-shrink-0 mr-1 box-border">
            {/* //买入卖出 */}
            <TransactionMode />
            <div key={orderKey + transactionMode}>
              {/*左侧表单*/}
              <TransactionLeftForm setIsBuyFull={setIsBuyFull} />
            </div>

            {/*交易信息 购买按钮*/}
            <TransactionInformation
              isBuyFull={isBuyFull}
              onSuccess={() => {
                onAmountChange(0.01);
                walletFundReq();
              }}
            />
          </div>
          <div className="!flex-shrink-0 w-[40%] ml-1 box-border">
            <TransactionRightBox
              num={priceType === TransactionTypeEnum.LIMIT ? 6 : 6}
            />
          </div>
        </div>
        <div className="min-h-[40vh] ">
          <CoinOrderList symbol={localCoin?.baseId} siftNode={<></>} />
        </div>
      </div>
    </CoinTradeContext.Provider>
  );
};
