import { TeenyiconsDownSolid } from '@/assets/icons/comm/TeenyiconsDownSolid';
import BaseInputNumber from '@/components/base/baseInputNumber';
import BaseSlideRange from '@/components/base/baseSlideRange';
import RenderUtil from '@/utils/RenderUtil';
import { Button, useDisclosure } from '@nextui-org/react';
import { useModel } from '@umijs/max';
import { useUpdateEffect } from 'ahooks';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCoinModel } from '../coinModel';
import { TransactionTypeEnum } from '../type';
import TransactionLimitOrderSelect from './TransactionLimitOrderSelect';

interface TransactionLeftFormProps {
  setIsBuyFull: (value: boolean) => void;
}

const parser = (value: string) => {
  if (!value) return value;
  return value?.replace(/\$\s?|(,*)/g, '');
};

export default ({ setIsBuyFull }: TransactionLeftFormProps) => {
  const modelProps = useCoinModel();
  const {
    price,
    amount,
    onPriceChange,
    onAmountChange,
    priceTypeOption,
    priceType,
    onPriceTypeChange,
    priceTypeText,
    transactionMode,
    activationPrice, //触发价，
    setActivationPrice,
  } = modelProps;

  // 使用state来追踪当前选中的值以及外部控制的显示/隐藏状态
  const { isOpen, onOpen, onOpenChange, onClose } = useDisclosure();
  const { localCoin } = useModel('coin');
  const { getSocketRowByName } = useModel('socket');
  const { t } = useTranslation();
  const socketData = getSocketRowByName(localCoin?.symbol);
  const { coinsFunds, renderFunds } = useModel('user');

  // 限价的价格 或者行情价
  const memoPrice =
    +priceType === TransactionTypeEnum.LIMIT ? price : socketData?.a;

  // 手续费类型
  // 1 固定值：手续费
  // 2 百分比：买入->交易金额 * 手续费比例；卖出->交易金额 * 市价 * 手
  const commissionType = localCoin?.commissionType ?? 0;

  //手续费
  const commission = localCoin?.commission;

  // 一个手续费
  const onePremium = useMemo(() => {
    // 固定手续费 -》 买入 和 卖出 都一样
    if (commissionType === 1) {
      return commission;
    }
    // 比例 买入计算 -》当前价 * 手续费比例
    if (commissionType === 2 && transactionMode === 1) {
      return commission * 0.01;
    }
    // 比例 卖出计算 -》交易金额 * 市价 * 1手
    if (commissionType === 2 && transactionMode === 2) {
      return 1 * memoPrice * (commission * 0.01);
    }
    return 0;
  }, [
    commissionType,
    localCoin,
    commission,
    transactionMode,
    amount,
    socketData?.a,
  ]);

  // 交易精度，后台控制值，输入框
  const transactionAccuracy = localCoin?.transactionAccuracy ?? 6;
  // 价格精度
  const priceAccuracyLength = localCoin?.priceAccuracy ?? 6;
  // 交易额
  const [transactionVolume, setTransactionVolume] = useState(0);
  
  const renderList = useMemo(() => {
    return coinsFunds?.walletList?.map((item: any) => {
      // 查找socket推送的币币列表
      const findItem = renderFunds?.coin?.walletList?.find?.(
        (fundsItem: any) => {
          return fundsItem?.name === item?.name;
        },
      );
      return {
        ...item,
        availableBalance: findItem?.ab ?? item?.availableBalance,
        convertUsd: findItem?.abu ?? item?.convertUsd,
        freezeBalance: findItem?.fb ?? item?.freezeBalance,
      };
    });
  }, [coinsFunds, renderFunds]);
  // 获取可用金额
  const availableAmount = useMemo(() => {
    // 买入 交换币 -》基币钱包
    // 卖出 基币钱包 -》 交换币钱包
    if (
      localCoin &&
      renderList?.length !== 0 &&
      transactionMode === 1
    ) {
      return renderList?.find(
        (item: any) => item.coinId === localCoin?.exchangeId,
      );
    }
    if (
      localCoin &&
      renderList?.length !== 0 &&
      transactionMode === 2
    ) {
      return renderList?.find(
        (item: any) => item.coinId === localCoin?.baseId,
      );
    }
  }, [localCoin, renderList, transactionMode]);

  // 最大手数 == 100% 的手数
  const maxLot = useMemo(() => {
    // 余额 减去手续费 除以  1手价钱 得到 100% 的手数
    if (transactionMode === 1) {
      if (commissionType === 1) {
        return availableAmount?.availableBalance;
      }
      // @ts-ignore
      return availableAmount?.availableBalance;
    }
    return availableAmount?.availableBalance;
  }, [availableAmount?.availableBalance, transactionMode, onePremium]);

  //仓位列表
  const [currentPosition, setCurrentPosition] = useState(0);

  //处理拉杆
  function handleSliderChange(value: any) {
    // 确保 userAmount 和 soketData 已经正确加载
    // console.log(value, value);
    //
    // console.log(!availableAmount?.availableBalance, 'xxx');
    //
    // console.log(socketData?.a, 'socketData?.a');

    if (!availableAmount?.availableBalance || !socketData?.a) {
      return;
    }

    setIsBuyFull(value === 100);


    // 最大手数 * 拉杆值 *0.01
    // 购买 需要 / 行情
    let maxNum = (maxLot / memoPrice) * value * 0.01;

    // console.log(typeof transactionMode, 'transactionMode');
    // console.log(transactionMode, 'transactionMode');

    if (transactionMode === 2) {
      // 卖出不能 ÷ 行情
      maxNum = maxLot * (value * 0.01);
    }

    // console.log(memoPrice, 'memoPrice');
    // console.log(maxLot, 'maxLot');
    // console.log(value, 'value');
    // console.log(maxNum, 'maxNum');
    // console.log(RenderUtil.formatNum(maxNum, 6), 'formatNum');

    if (maxNum < 0.001) {
      maxNum = 0.01;
    }
    setTransactionVolume(maxNum * memoPrice);

    // 设置最终计算的购买数量
    onAmountChange(RenderUtil.formatNum(maxNum, transactionAccuracy));
    setCurrentPosition(value);
  }

  // 输入数量设置拉杆位置
  const onAmountChangeSetCurrentPosition = (value: any) => {
    if (!availableAmount?.availableBalance || !socketData?.a) {
      return;
    }
    // 最大手数 * 拉杆值 *0.01
    // 购买 需要 / 行情
    let maxNum = (maxLot / memoPrice) * 100 * 0.01;
    if (transactionMode === 2) {
      // 卖出不能 ÷ 行情
      maxNum = maxLot * (100 * 0.01);
    }
    if (maxNum < 0.001) {
      maxNum = 0.01;
    }
    let currentPosition = value / maxNum * 100;
    if (currentPosition > 100) {
      currentPosition = 100;
    }
    if (currentPosition < 0) {
      currentPosition = 0;
    }
    setCurrentPosition(currentPosition);
  };

  useEffect(() => {
    onAmountChange(0);
  }, []);


  useUpdateEffect(() => {
    // 市价变化到 限价 时候，直接赋值到当前价
    if (+priceType === TransactionTypeEnum.LIMIT && socketData?.a) {
      onPriceChange(RenderUtil.formatNum(socketData?.a, transactionAccuracy));
    }
  }, [priceType, localCoin]);

  useEffect(() => {
    if (+amount === 1) {
      handleSliderChange(amount);
    }
  }, [location.pathname, availableAmount?.availableBalance]);

  useUpdateEffect(() => {
    handleSliderChange(0);
  }, [transactionMode]);


  return (
    <>
      {/*限价格*/}
      <TransactionLimitOrderSelect
        onChange={onPriceTypeChange as any}
        options={priceTypeOption as any}
        onOpenChange={onOpenChange}
        onClose={onClose}
        isOpen={isOpen}
        value={priceType as any}
      >
        <Button
          className="grayButton w-full  !px-2 flex justify-between select-all h-[28px] text-[14px]"
          onClick={onOpen}
        >
          <span></span>
          <span className="exclamationMark text-titleColor">
            {priceTypeText}
          </span>
          <TeenyiconsDownSolid
            width="8px"
            height="6px"
            className="text-auxiliaryTextColor"
          />
        </Button>
      </TransactionLimitOrderSelect>

      {/*止盈/止损表单*/}
      {+priceType === TransactionTypeEnum.STOP_LOSS && (
        <>
          <BaseInputNumber
            className="my-2"
            title={t('触发价格')}
            value={activationPrice}
            step={1 / Math.pow(10, priceAccuracyLength ?? 0)}
            len={priceAccuracyLength}
            onChange={setActivationPrice}
          />

          <BaseInputNumber
            className="my-2"
            title={t('委托价格')}
            value={price}
            step={1 / Math.pow(10, priceAccuracyLength ?? 0)}
            len={priceAccuracyLength}
            onChange={onPriceChange}
          />

          <BaseInputNumber
            className="my-2"
            title={t('数量')}
            value={amount}
            step={1 / Math.pow(10, transactionAccuracy ?? 0)}
            len={amount === 0 ? 0 : transactionAccuracy}
            onChange={(value: number) => {
              if (value) {
                // 只要输入流数量，则默认不要全部购买
                setIsBuyFull(false);
              }
              onAmountChange(value);
            }}
          />
        </>
      )}

      {/*限价单表单*/}
      {+priceType === TransactionTypeEnum.LIMIT && (
        <>
          <BaseInputNumber
            className="my-2"
            title={t('价格')}
            value={price}
            step={1 / Math.pow(10, priceAccuracyLength ?? 0)}
            len={priceAccuracyLength}
            onChange={onPriceChange}
          />
          <BaseInputNumber
            className="mt-2"
            title={t('数量')}
            value={amount}
            step={1 / Math.pow(10, transactionAccuracy ?? 0)}
            len={transactionAccuracy}
            onChange={(value: number) => {
              if (value) {
                // 只要输入流数量，则默认不要全部购买
                setIsBuyFull(false);
              }
              onAmountChange(value);
            }}
            onRealTimeInput={(value: any) => {

              onAmountChangeSetCurrentPosition(Number(parser(value)));
              setTransactionVolume(Number(parser(value)) * memoPrice);
            }}

          />
        </>
      )}

      {/*市价单表单*/}
      {+priceType === TransactionTypeEnum.MARKET && (
        <>
          <div className="flex items-center justify-center
           bg-inputDisabledBg text-auxiliaryTextColor text-sm  dark:bg-backgroundAuxiliaryColor  h-[40px] text-center rounded-[6px] my-2 py-2 select-none">
            {t('市价')}
          </div>

          <BaseInputNumber
            title={t('数量')}
            value={amount}
            className="!mb-0"
            showButton={false}
            step={1 / Math.pow(10, transactionAccuracy ?? 0)}
            len={transactionAccuracy}
            onChange={(value: number) => {
              if (value) {
                // 只要输入流数量，则默认不要全部购买
                setIsBuyFull(false);
              }
              onAmountChange(value);
            }}
            onRealTimeInput={(value: any) => {

              onAmountChangeSetCurrentPosition(Number(parser(value)));

              setTransactionVolume(Number(parser(value)) * memoPrice);
            }}
          />
        </>
      )}


      <div className="pt-[10px] pb-[1px] px-2">
        {/* 拉杆组件 */}
        <BaseSlideRange
          values={[1, 25, 50, 75, 100]}
          step={1}
          value={currentPosition}
          onChange={handleSliderChange}
        />
      </div>


      <BaseInputNumber
        title={t('交易额')}
        value={transactionVolume}
        className="!mt-3"
        showButton={false}
        step={1 / Math.pow(10, transactionAccuracy ?? 0)}
        len={transactionAccuracy}
        onChange={(value: number) => {
          if (value) {
            // 只要输入流数量，则默认不要全部购买
            setIsBuyFull(false);
          }
          setCurrentPosition(0);
          onAmountChange(value / memoPrice);
          setTransactionVolume(value);
        }}
      />


      {/*分时图*/}
      {/*{isMobile && <TimeChart />}*/}
    </>
  );
};
