import { useModel } from '@umijs/max';
import { useTranslation } from 'react-i18next';
import { useCoinModel } from '../coinModel';
import { TransactionModeEnum } from '../type';

export default () => {
  const { t } = useTranslation();
  const modelProps = useCoinModel();
  const { transactionMode, changeTransactionMode } = modelProps;

  //红涨绿跌设置
  const { currentDownBgClass, currentUpBgClass, riseColor, fallColor } =
    useModel('system');

  const buyBorderColor =
    riseColor === 'errorColor'
      ? `border-l-errorColor`
      : `border-l-successColor`;
  const sellBorderColor =
    fallColor === 'errorColor'
      ? `border-r-errorColor`
      : `border-r-successColor`;


  return (
    <>
      {/*选择买入或者卖出*/}
      <div
        className="border-1 border-borderColor mb-2 h-[28px] text-[14px]  overflow-hidden rounded-[6px] flex items-center">
        <div
          onClick={() => {
            changeTransactionMode(TransactionModeEnum.BUY);
          }}
          className={`flex-1  relative text-center  h-full ${
            transactionMode === TransactionModeEnum.BUY
              ? ` text-[#fff]`
              : 'text-auxiliaryTextColor'
          }`}
        >
          {transactionMode === TransactionModeEnum.BUY && (
            <>
              {
                riseColor !== 'errorColor' &&
                <img className="absolute z-1 w-full h-full" src={require('@/assets/img/spotGoods/greenRight.png')} alt="" />
              }
              {
                riseColor === 'errorColor' &&
                <img className="absolute z-1 w-full h-full" src={require('@/assets/img/spotGoods/redRight.png')} alt="" />
              }
            </>
          )}
          <div className="absolute w-full leading-[28px]  z-8  text-center">   {t('买入')}</div>

        </div>
        <div
          onClick={() => {
            changeTransactionMode(TransactionModeEnum.SELL);
          }}
          className={`flex-1  relative text-center  h-full leading-[24px] ${
            transactionMode === TransactionModeEnum.SELL
              ? ` text-[#fff]`
              : ' text-auxiliaryTextColor'
          }`}
        >
          {transactionMode === TransactionModeEnum.SELL && (
            <>
              {
                riseColor === 'errorColor' &&
                <img className="absolute z-1 w-full h-full rotate-180" src={require('@/assets/img/spotGoods/greenRight.png')}
                     alt="" />
              }
              {
                riseColor !== 'errorColor' &&
                <img className="absolute z-1 w-full h-full rotate-180" src={require('@/assets/img/spotGoods/redRight.png')}
                     alt="" />
              }
            </>
          )}
          <div className="absolute w-full leading-[28px]  z-8  text-center">   {t('卖出')}</div>

        </div>
      </div>
    </>
  );
};
