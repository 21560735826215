import { TeenyiconsDownSolid } from '@/assets/icons/comm/TeenyiconsDownSolid';
import { IcSharpSignpost } from '@/assets/icons/transaction/IcSharpSignpost';
import ValueDisplay from '@/components/display/displayValueDisplay';
import { GetCoinListTypeEnum } from '@/enums/businessEnum';
import PageEnum from '@/enums/pageEnum';
import { ChartTypeEnum } from '@/models/kline';
import { history, useModel } from '@umijs/max';
import { useMount, useUnmount, useUpdateEffect } from 'ahooks';
import { useEffect, useLayoutEffect, useState } from 'react';

export default () => {
  
  const { toChart } = useModel('kline');
  const { getSocketRowByName, sendMsg, isSub } = useModel('socket');
  const { localCoin } = useModel('coin');
  const {setLocalChoiceContractSocket} = useModel("system");

  const socketData = getSocketRowByName(localCoin?.symbol);
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    if (isSub) {
      sendMsg(`{"type":"SUB","data": [${JSON.stringify(localCoin?.symbol)}]}`);
    }
  }, [localCoin?.symbol, isSub]);

  const [tabSelected] = useState(GetCoinListTypeEnum.COIN);
  const [params, setParams] = useState<any>({
    pageNo: 1,
    pageSize: 20,
    type: tabSelected,
  });

  useUpdateEffect(() => {
    // 搜索框输入时，延迟500ms再请求
    setParams({
      ...params,
      fuzzy: filterText,
      pageNo: 1,
      type: tabSelected,
    });
  }, [tabSelected, filterText]);

  // 设置 游览器标签 title
  useEffect(()=>{
    // 币币/现货
    if(localCoin&&location.pathname===PageEnum.TRANSACTION)
        setLocalChoiceContractSocket(localCoin)
    if(localCoin&&location.pathname===PageEnum.HOME)
        setLocalChoiceContractSocket(null)
  },[localCoin,location.pathname])

  // 取消订阅
  useUnmount(()=>{
    setLocalChoiceContractSocket(null)
  })
  return (
    <div className="flex flex-wrap justify-start items-start px-4 mt-3">
      <div className="w-full">
        <div className="w-full h-[20px] justify-between flex text-base items-center">
          <div
            className="w-fit font-bold flex items-center"
            onClick={() => {
              history.push(
                PageEnum.SEARCH + '?type=' + GetCoinListTypeEnum.COIN,
              );
            }}
          >
            <span className="text-titleColor text-[18px]">{localCoin?.alias}</span>
            <TeenyiconsDownSolid width="0.5em" height="2em" className="ml-2" />
          </div>
          {/* 图标 */}
          <div>
            <IcSharpSignpost
              className="text-auxiliaryTextColor"
              width="1.5em"
              height="1.5em"
              onClick={() => toChart(ChartTypeEnum.COIN)}
            />
          </div>
        </div>
      </div>
      <span className="text-success text-xs">
        <ValueDisplay value={socketData?.pe} ratio justify="justify-start" />
      </span>
    </div>
  );
};
