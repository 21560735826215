import ValueDisplay from '@/components/display/displayValueDisplay';
import usePageRefreshOnVisible from '@/hooks/usePageRefreshOnVisible';
import RenderUtil from '@/utils/RenderUtil';
import { useModel } from '@umijs/max';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useCoinModel } from '../coinModel';
import { truncateNumber } from '@/utils/number';

/**
 * 买卖价格波动组件
 * @returns
 */
interface TransactionRightBoxProps {
  num: number;
}

export default function TransactionRightBox({ num }: TransactionRightBoxProps) {
  const modelProps = useCoinModel();
  const { onPriceChange } = modelProps || {};
  const { t } = useTranslation();
  const { localCoin }: any = useModel('coin');
  const { getSocketRowByName, sendMsg, isSub, coinDepthList }: any =
    useModel('socket');

  const getSocketCoinDepthByName = (name: string): any => {
    if (coinDepthList.length) {
      const cur = coinDepthList?.find((i: any) => i.p === name);
      if (cur) return cur;
    }
    return {
      a: [],
      b: [],
      p: '',
    };
  };

  const socketData = getSocketRowByName(localCoin?.symbol);
  const depth = getSocketCoinDepthByName(localCoin?.symbol);

  const transactionAccuracy = localCoin?.transactionAccuracy ?? 3;
  const priceAccuracy = localCoin?.priceAccuracy ?? 3;

  const {
    reassembledBuyArr,
    reassembledSellArr,
    maxAsks,
    minAsks,
    maxBids,
    minBids,
  } = useMemo(() => {
    const getMinMax = (arr: any[], key: number) => {
      const values = arr.map((item) => item?.[key] || 0);
      return {
        max: Math.max(...values),
        min: Math.min(...values),
      };
    };
    const sortAndReassemble = (arr: any[], isSell: boolean) => {
      // 按 p 降序排序
      const sortedArr = [...arr].sort((a, b) => b.p - a.p)?.map?.(item => {
        return {
          ...item,
          p: truncateNumber(item.p, priceAccuracy),
        };
      });

      // 用 Map 记录去重后的数据 (键为 p，值为 n)
      const uniqueMap = new Map();
      sortedArr.forEach((item) => {
        if (!uniqueMap.has(item.p)) {
          uniqueMap.set(item.p, item.n);
        }
      });

      // 生成去重后的数组
      const deduplicatedArr = Array.from(uniqueMap, ([p, n]) => ({ p, n }));

      // 提取去重后的 n 值数组
      const sortedPrices = deduplicatedArr.map((item) => item.n);

      // 组合返回
      return deduplicatedArr.map((item, index) => [item.p, sortedPrices[index]]);
    };
    const buyArr = sortAndReassemble(depth?.a || [], false);
    const sellArr = sortAndReassemble(depth?.b || [], true);

    const { max: maxAsks, min: minAsks } = getMinMax(buyArr, 0);
    const { max: maxBids, min: minBids } = getMinMax(sellArr, 0);
    return {
      reassembledBuyArr: buyArr,
      reassembledSellArr: sellArr,
      maxAsks,
      minAsks,
      maxBids,
      minBids,
    };
  }, [depth]);


  // 回到页面刷新
  const pageKey = usePageRefreshOnVisible();
  const { readyState } = useModel('socket');

  useEffect(() => {
    if (localCoin && isSub && pageKey) {
      // UNSUB_COIN_DEPTH
      sendMsg(
        `{"type":"SUB_COIN_DEPTH","data": ${JSON.stringify(
          localCoin?.symbol,
        )}}`,
      );
    }
  }, [localCoin, isSub, pageKey, readyState]);




  return (
    <div className="pl-2 h-full flex flex-col">
      <div className="flex justify-between text-[11px] pt-[2px] pb-[4px]">
        <div>
          <div className="text-auxiliaryTextColor">{t('价格')}</div>
        </div>

        <div>
          <div className="text-auxiliaryTextColor">{t('数量')}</div>
        </div>
      </div>
      <div className="flex flex-1 flex-col">
        <div>
          {reassembledBuyArr?.slice(0, num)?.map((item, index) => {
            // 计算当前项的百分比宽度
            const gradientLength =
              ((item?.[0] - minAsks) / (maxAsks - minAsks)) * 100;

            return (
              <div
                key={index}
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                }}
                className="pt-[1px] pb-[1px] "
                onClick={() => {
                  onPriceChange(RenderUtil.formatNum(item?.[0], priceAccuracy));
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: gradientLength,
                    background: 'rgba(255,0,0,0.05)',
                  }}
                />
                <div className="flex justify-between mb-[8px]">
                  <div className="mb0">
                    <div>
                      <ValueDisplay
                        propsColor="#f23645"
                        className="text-[11px]"
                        value={item?.[0]}
                        len={localCoin?.priceAccuracy}
                      />
                    </div>
                  </div>

                  <div className="mb0 flex justify-center items-center">
                    <div className="text-[11px]">
                      {RenderUtil.shortenNumber(
                        RenderUtil.formatNum(
                          item?.[1],
                          transactionAccuracy ?? item?.[1] < 1 ? 5 : 2,
                        ),
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="text-xs py-1 pb-2">
          <div className="flex justify-center">
            <div className="text-base">
              {/* 当前价 */}
              <ValueDisplay
                className="text-base"
                value={socketData.a}
                len={localCoin?.priceAccuracy}
                isSubLen={false}
                justify="justify-start"
              />
            </div>
          </div>
        </div>

        <div>
          {reassembledSellArr?.slice(0, num)?.map((item, index) => {
            const gradientLength =
              ((item?.[0] - minBids) / (maxBids - minBids)) * 100;

            return (
              <div
                key={index}
                style={{
                  position: 'relative',
                  overflow: 'hidden',
                }}
                className="pt-[1px] pb-[1px]"
                onClick={() => {
                  onPriceChange(RenderUtil.formatNum(item?.[0], priceAccuracy));
                }}
              >
                <div
                  style={{
                    position: 'absolute',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    width: gradientLength,
                    background: 'rgba(144,238,144,0.05)',
                  }}
                />
                <div className="flex justify-between mb-[9px]">
                  <div className="mb0">
                    <ValueDisplay
                      propsColor="#2db880"
                      className="text-[11px] my-[0]"
                      value={item?.[0]}
                      len={localCoin?.priceAccuracy}
                    />
                  </div>

                  <div className="text-[11px] text-center flex justify-center items-center">
                    {RenderUtil.shortenNumber(
                      RenderUtil.formatNum(
                        item?.[1],
                        transactionAccuracy ?? item?.[1] < 1 ? 5 : 2,
                      ),
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
